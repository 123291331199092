import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { openModal, closeModal } from '@redq/reuse-modal';
import NavbarWrapper from 'common/src/components/Navbar';
import Drawer from 'common/src/components/Drawer';
import Button from 'common/src/components/Button';
import Logo from 'common/src/components/UIElements/Logo';
import HamburgMenu from 'common/src/components/HamburgMenu';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';
import { Container } from './navbar.style';
// import SearchPanel from '../SearchPanel';
// import LoginModal from '../LoginModal';
import CopyrightSection from '../CopyrightsSection';

import LogoImage from 'common/src/assets/image/agency/logo-horizontal.webp';
import LogoInvertidoImage from 'common/src/assets/image/agency/logo-horizontal-invertido.webp';

import { DrawerContext } from 'common/src/contexts/DrawerContext';

const Navbar = ({ navbarStyle, logoStyle, turnOffLogoToggling, page }) => {
  const { state, dispatch } = useContext(DrawerContext);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', toggleLogos);
  }, []);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  /** Displays/Hides the buttons */
  const toggleLogos = () => {
    /** If the current current scroll is greater than 0 */
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const Data = useStaticQuery(graphql`
    query {
      agencyJson {
        menuItems {
          label
          path
          offset
        }
        menuItemsService {
          label
          path
          offset
        }
      }
    }
  `);

  let filteredMenuItems;

  if (page === 'service') {
    filteredMenuItems = Data.agencyJson.menuItemsService;
  } else {
    filteredMenuItems = Data.agencyJson.menuItems.filter(item => {
      return item.label !== 'FAQ';
    });
  }

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container>
        <Logo
          href="/"
          logoSrc={
            turnOffLogoToggling
              ? LogoImage
              : isScrolled
              ? LogoImage
              : LogoInvertidoImage
          }
          title="CuriosityLab"
          logoStyle={logoStyle}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/*<Button
            variant="textButton"
            onClick={handleSearchModal}
            icon={<i className="flaticon-magnifying-glass" />}
            aria-label="search"
          />*/}
          {/*<Button
            variant="textButton"
            onClick={handleLoginModal}
            icon={<i className="flaticon-user" />}
            aria-label="login"
          />*/}
          <Drawer
            width="420px"
            placement="right"
            drawerHandler={<HamburgMenu />}
            open={state.isOpen}
            toggleHandler={toggleHandler}
          >
            <ScrollSpyMenu
              menuItems={filteredMenuItems}
              drawerClose={true}
              offset={-100}
            />
            <CopyrightSection />
          </Drawer>
        </div>
      </Container>
    </NavbarWrapper>
  );
};

// Navbar style props
Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

Navbar.defaultProps = {
  // Default navbar style
  navbarStyle: {
    minHeight: '70px',
  },
  // Default logo size
  logoStyle: {
    width: '128px',
    height: 'auto',
  },
};

export default Navbar;
