import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Card from 'common/src/components/Card';
import Button from 'common/src/components/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import AboutUsSectionWrapper from './aboutUsSection.style';

const HighlightSection = ({
  row,
  col,
  title,
  description,
  textArea,
  featureTitle,
  btnStyle,
  page,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      image1: file(
        relativePath: { eq: "image/agency/highlight/foreplay.webp" }
      ) {
        childImageSharp {
          fluid(maxWidth: 505, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "image/agency/highlight/jak.webp" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(
        relativePath: { eq: "image/agency/highlight/dd-cart.webp" }
      ) {
        childImageSharp {
          fluid(maxWidth: 505, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(
        relativePath: { eq: "image/agency/highlight/swagger.webp" }
      ) {
        childImageSharp {
          fluid(maxWidth: 505, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image5: file(
        relativePath: { eq: "image/agency/highlight/dindong.webp" }
      ) {
        childImageSharp {
          fluid(maxWidth: 505, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image6: file(
        relativePath: { eq: "image/agency/highlight/assisthr.webp" }
      ) {
        childImageSharp {
          fluid(maxWidth: 505, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image7: file(
        relativePath: { eq: "image/agency/highlight/dashboard.webp" }
      ) {
        childImageSharp {
          fluid(maxWidth: 505, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      agencyJson {
        aboutus {
          id
          title
          icon
        }
        aboutusMobileAppService {
          id
          title
          icon
        }
      }
    }
  `);

  return (
    <AboutUsSectionWrapper id="aboutUsSection">
      <Box className="row" {...row}>
        <Box className="col" {...col}>
          {page === 'home' && (
            <Card className="group-gallery">
              <Box className="col1">
                <Fade top delay={30}>
                  <Image
                    fluid={
                      (Data.image5 !== null) | undefined
                        ? Data.image5.childImageSharp.fluid
                        : {}
                    }
                    objectFit="contain"
                    alt="DinDong"
                  />
                </Fade>
                <Fade left delay={60}>
                  <Image
                    fluid={
                      (Data.image6 !== null) | undefined
                        ? Data.image6.childImageSharp.fluid
                        : {}
                    }
                    objectFit="contain"
                    alt="AssistHR"
                  />
                </Fade>
              </Box>
              <Box className="col2">
                <Fade bottom delay={90}>
                  <Image
                    fluid={
                      (Data.image2 !== null) | undefined
                        ? Data.image2.childImageSharp.fluid
                        : {}
                    }
                    objectFit="contain"
                    alt="Jak Logistics"
                  />
                </Fade>
              </Box>
            </Card>
          )}
          {page === 'services-mobile-app' && (
            <Card className="group-gallery">
              <Box className="col3">
                <Fade bottom delay={90}>
                  <Image
                    fluid={
                      (Data.image1 !== null) | undefined
                        ? Data.image1.childImageSharp.fluid
                        : {}
                    }
                    objectFit="contain"
                    alt="Foreplay App"
                  />
                </Fade>
              </Box>
              <Box className="col3">
                <Fade bottom delay={90}>
                  <Image
                    fluid={
                      (Data.image2 !== null) | undefined
                        ? Data.image2.childImageSharp.fluid
                        : {}
                    }
                    objectFit="contain"
                    alt="Jak Logistics"
                  />
                </Fade>
              </Box>
              <Box className="col3">
                <Fade bottom delay={90}>
                  <Image
                    fluid={
                      (Data.image3 !== null) | undefined
                        ? Data.image3.childImageSharp.fluid
                        : {}
                    }
                    objectFit="contain"
                    alt="DinDong"
                  />
                </Fade>
              </Box>
            </Card>
          )}
        </Box>
        <Box className="col" {...col}>
          <Box {...textArea}>
            <FeatureBlock
              title={
                <Heading content="We build effective products" {...title} />
              }
              description={
                <Text
                  content="Our expertise and methodology ensures your product meets all expectations in the most efficient way."
                  {...description}
                />
              }
            />
          </Box>
          <Box {...textArea}>
            {page === 'home' &&
              Data.agencyJson.aboutus.map((feature, index) => (
                <FeatureBlock
                  key={`feature_point-${index}`}
                  icon={<i className={feature.icon} />}
                  iconPosition="left"
                  title={<Heading content={feature.title} {...featureTitle} />}
                />
              ))}
            {page === 'services-mobile-app' &&
              Data.agencyJson.aboutusMobileAppService.map((feature, index) => (
                <FeatureBlock
                  key={`feature_point-${index}`}
                  icon={<i className={feature.icon} />}
                  iconPosition="left"
                  title={<Heading content={feature.title} {...featureTitle} />}
                />
              ))}
            {/*<Button title="GET QUOTE" {...btnStyle} />*/}
          </Box>
        </Box>
      </Box>
    </AboutUsSectionWrapper>
  );
};

HighlightSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textArea: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

HighlightSection.defaultProps = {
  // About us section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // About us section col default style
  col: {
    width: [1, '100%', '50%'],
  },
  // About us section text area default style
  textArea: {
    maxWidth: '490px',
    pl: '40px',
  },
  // About us section title default style
  title: {
    fontSize: ['26px', '26px', '30px', '40px'],
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '30px',
  },
  // About us section description default style
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
  },

  // feature title default style
  featureTitle: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#343d48',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
};

export default HighlightSection;
