import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import Input from 'common/src/components/Input';
import SectionWrapper, {
  DonationForm,
  DonateButton,
} from './donateSection.style';

const ContactUsSection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  netlifyForm,
}) => {
  const data = useStaticQuery(graphql`
    query {
      agencyJson {
        emailSubjectOptions {
          id
          title
          value
        }
      }
    }
  `);

  const [state, setState] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [sent, setSent] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleFormData = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleDonation = e => {
    e.preventDefault();
    setIsSending(true);
    const myForm = e.target;
    let formData = new FormData(myForm);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setSent(true);
        setIsSending(false);
        console.log('Contact Form successfully submitted');
      })
      .catch(error => {
        alert(error);
        setIsSending(false);
      });
  };

  return (
    <SectionWrapper id="contactUsSection">
      <Container width="1260px">
        <Box {...sectionHeader}>
          <Text content="Contact Us" {...sectionSubTitle} />
          <Heading
            content="Send us a message and we'll get back to you"
            {...sectionTitle}
          />
        </Box>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <DonationForm
              onSubmit={e => handleDonation(e)}
              data-netlify="true"
              name={netlifyForm}
              method="POST"
            >
              <input type="hidden" name="form-name" value="contact" />
              <Input
                inputType="text"
                placeholder="Name"
                value={state.name}
                netlifyName="name"
                onChange={e => handleFormData(e, 'name')}
              />
              <Input
                inputType="email"
                placeholder="E-mail"
                value={state.email}
                netlifyName="email"
                onChange={e => handleFormData(e, 'email')}
              />
              <Input
                inputType="text"
                placeholder="Subject"
                value={state.subject}
                netlifyName="subject"
                onChange={e => handleFormData(e, 'subject')}
              />
              <Input
                inputType="textarea"
                placeholder="Message"
                value={state.message}
                netlifyName="message"
                onChange={e => handleFormData(e, 'message')}
              />
              <DonateButton type="submit" disabled={sent || isSending}>
                {isSending
                  ? 'Sending Message...'
                  : sent
                  ? 'Message Sent'
                  : 'Send Message'}
              </DonateButton>
            </DonationForm>
          </Box>
        </Box>
      </Container>
    </SectionWrapper>
  );
};

// ContactUsSection style props
ContactUsSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textStyle: PropTypes.object,
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
};

// ContactUsSection default style
ContactUsSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['40px', '56px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#10ac84',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '400',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // ContactUsSection row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // ContactUsSection col default style
  col: {
    width: [1, '100%', '80%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
    mx: 'auto',
  },
};

export default ContactUsSection;
